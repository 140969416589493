import { css } from '@emotion/react'
import { theme } from '@dfds-ui/theme'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

import { AspectImage } from '.'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const ImageSlider = (props) => {
  const {
    aspectRatio,
    autoplay,
    pagination = true,
    imageCollection,
    navigation = true,
    border,
    imageBehaviour,
  } = props
  return (
    imageCollection && (
      <Swiper
        navigation={navigation}
        autoplay={
          autoplay && {
            delay: 2500,
            disableOnInteraction: true,
          }
        }
        pagination={
          pagination && {
            clickable: true,
          }
        }
        modules={[Autoplay, Pagination, Navigation]}
        css={css`
          z-index: unset !important;
          width: 100vw;
          max-width: 100%;
          margin: 0 !important;

          .swiper,
          .swiper-wrapper,
          .swiper-button-next,
          .swiper-button-prev,
          .swiper-pagination {
            z-index: unset;
            color: ${theme.colors.text.light.primary};
          }
          .swiper-button-next,
          .swiper-button-prev {
            width: 40px;
            height: 40px;
            background-color: ${theme.colors.secondary.main};
          }
          .swiper-button-next {
            border-radius: 50% 0 0 50%;
            margin-right: -16px;
          }
          .swiper-button-prev {
            border-radius: 0 50% 50% 0;
            margin-left: -16px;
          }
          .swiper-button-next::after,
          .swiper-button-prev::after {
            font-size: 20px;
          }
          .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
          }
          .swiper-pagination-bullet-active {
            background: ${theme.colors.secondary.main};
          }
        `}
      >
        {imageCollection.map((image) => (
          <SwiperSlide key={image.sys.id}>
            <AspectImage
              {...image}
              aspectRatio={aspectRatio}
              border={border}
              imageBehaviour={imageBehaviour}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    )
  )
}

export default ImageSlider
